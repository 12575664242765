<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { checkBrowserPermissions } from '@/utils/permissions';
import GlobalSnackbar from '@/components/commons/global-snack';

// FORCE REDIRECT IF NOT IN CONSULT PAGE
const route = useRoute();
const permissionDialog = ref(false);
onMounted(() => {
  console.warn('Checking route', route.name);
  // if (!route.name.startsWith('consult') && !route.name.startsWith('patient-demo')) {
  //   router.push('/404');
  // }

  checkBrowserPermissions(() => {
    if (route.name !== 'consult' || route.name !== 'patient-demo') return;
    permissionDialog.value = true;
  });
});
</script>


<template>
  <div data-theme="easyjoey" class="bg-[#f5f5f5] min-h-[100vh]">
    <dialog id="dialog" :class="`modal ${permissionDialog ? 'modal-open' : ''}`">
      <div method="dialog" class="modal-box">
        <div class="flex justify-between">
          <span class="text-xl font-medium">Notice</span>
          <button
            class="btn btn-sm btn-circle btn-ghost"
            @click="permissionDialog = false"
          >
            <i class="las la-times text-2xl text-red-400"></i>
          </button>
        </div>
        <div class="pb-10 flex flex-col gap-6 mt-5">
          <p class="text-base">For the best demo experience please allow the browser to access your camera and microphone to start the video call.</p>
          <p class="text-base">If you are using Google Chrome click allow in the pop-up presented or grant it manually by clicking on the icon on the uppper left part of your browser as seen in the image below.</p>
          <img src="../assets/images/permission.png" alt="Permissions Image" class="rounded-lg">
        </div>
      </div>
    </dialog>

    <global-snackbar />
    <slot />
  </div>
</template>

